import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import imageFiveDesktop from "./images/imagesDesktop/imageFive.webp"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"
import imageFiveMobile from "./images/imagesMobile/imageFive.webp"



const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Llamadas a todo el mundo",
      description: "Obtén la capacidad de marcar y recibir llamadas internacionales, personalizar el identificador de llamadas y controlar el flujo de llamadas entrantes y salientes utilizando nuestras funcionalidades avanzadas disponibles.",
      image: imageOneMobile,
      background: "#D3F7FA"
    },
    {
      title: "Enmascaramiento de números",
      description: "Optimiza tus llamadas salientes con nuestras máscaras de números personalizables. Evita bloqueos y reportes, garantizando una comunicación efectiva y aumentando la probabilidad de contactabilidad.",
      image: imageTwoMobile,
      background: "#FBE6E5"
    },
    {
      title: "Marcación por tonos",
      description: "Simplifica la tarea de obtener datos durante una llamada, ofreciendo instrucciones claras y reaccionando ágilmente a las acciones del usuario. Proporciona orientación detallada sobre cómo interactuar con el sistema y ofrece respuestas rápidas, adaptándose a las necesidades específicas de cada situación.",
      image: imageThreeMobile,
      background: "#EEEAFB"
    },
    {
      title: "Seguridad para tu empresa",
      description: "Para preservar tus conversaciones telefónicas con seguridad, contamos con protección contra DDoS y certificaciones como ISO 27001, SOC 2 y GDPR, garantizando así la integridad, confidencialidad y disponibilidad de tus datos.",
      image: imageFourMobile,
      background: "#FBE6E5"
    },
    {
      title: "Supervisión en tiempo real",
      description: "Tanto para agentes remotos como de oficina, podrás escuchar en tiempo real sus llamadas, acceder a grabaciones y a análisis de voz, lo que te permitirá supervisar la calidad del servicio, proporcionar retroalimentación y mejorar continuamente la experiencia del cliente.",
      image: imageFiveMobile,
      background: "#EEEAFB"
    },
  ]

  return (
    <section className="container-experience-voiceApi">
      <p className="container-experience-voiceApi-title">
        No solo automatizamos, también brindamos seguridad a tus operaciones y la <TitleUnderlineOne underline="continuidad de tus" /> <TitleUnderlineTwo underline="campañas" />
      </p>
      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsTwo">
        {/* columna */}
        <div className="container-experience-voiceApi-cardsTwo-cards-column">
          <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#EEEAFB" }}>
            <section>
              <p className="container-experience-voiceApi-cardsTwo-card-title">Llamadas a todo el mundo</p>
              <p className="container-experience-voiceApi-cardsTwo-card-description">
                Obtén la capacidad de marcar y recibir llamadas internacionales, personalizar el identificador de llamadas y controlar el flujo de llamadas entrantes y salientes utilizando nuestras funcionalidades avanzadas disponibles.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsTwo-card-image"
                src={imageOneDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
        {/* columna */}
        <section className="container-experience-voiceApi-cardsTwo-card" style={{ background: "#FBE6E5", padding: "32px 30px 0px 0px" }}>
          <section style={{ padding: "0px 0px 0px 32px" }} >
            <p className="container-experience-voiceApi-cardsTwo-card-title">Enmascaramiento de números</p>
            <p className="container-experience-voiceApi-cardsTwo-card-description">
              Optimiza tus llamadas salientes con nuestras máscaras de números personalizables. Evita bloqueos y reportes, garantizando una comunicación efectiva y aumentando la probabilidad de contactabilidad.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsTwo-card-image"
              src={imageTwoDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-voiceApi-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#FBE6E5", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-voiceApi-cardsOne-card-title">Marcación por tonos</p>
            <p className="container-experience-voiceApi-cardsOne-card-description">
              Simplifica la tarea de obtener datos durante una llamada, ofreciendo instrucciones claras y reaccionando ágilmente a las acciones del usuario. Proporciona orientación detallada sobre cómo interactuar con el sistema y ofrece respuestas rápidas, adaptándose a las necesidades específicas de cada situación.
            </p>
          </section>
          <section>
            <img
              className="container-experience-voiceApi-cardsOne-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-voiceApi-cardsOne-column">
          {/* columna */}
          <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#D3F7FA", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-voiceApi-cardsOne-card-title">Seguridad para tu empresa</p>
              <p className="container-experience-voiceApi-cardsOne-card-description">
                Para preservar tus conversaciones telefónicas con seguridad, contamos con protección contra DDoS y certificaciones como ISO 27001, SOC 2 y GDPR, garantizando así la integridad, confidencialidad y disponibilidad de tus datos.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsOne-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
          {/* columna */}
          <section className="container-experience-voiceApi-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-voiceApi-cardsOne-card-title">Supervisión en tiempo real</p>
              <p className="container-experience-voiceApi-cardsOne-card-description">
                Tanto para agentes remotos como de oficina, podrás escuchar en tiempo real sus llamadas, acceder a grabaciones y a análisis de voz, lo que te permitirá supervisar la calidad del servicio, proporcionar retroalimentación y mejorar continuamente la experiencia del cliente.
              </p>
            </section>
            <section>
              <img
                className="container-experience-voiceApi-cardsOne-card-image"
                src={imageFiveDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      <div className="container-experience-voiceApi-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-voiceApi-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-voiceApi-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-voiceApi-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                  </section>
                  <section>
                    <img
                      className="container-experience-voiceApi-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
