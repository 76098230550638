import React from "react"

export const TitleUnderlineVoiceApiOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="75" height="10" viewBox="0 0 75 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9437 10C12.3931 9.07169 1.03928 10.1081 0.214013 9.82831C-0.459736 9.45317 0.616973 2.84689 1.24237 1.87407C1.46158 1.52436 1.71625 1.47351 2.29973 1.63247C3.22493 1.93131 15.188 1.33997 15.188 1.33997C15.346 1.54344 15.8199 0.856744 15.9069 1.10472C16.1809 1.68332 35.5713 0.894891 37.2251 0.8631C38.2277 0.844025 37.4346 1.23824 38.6145 1.07928C41.129 0.761364 46.6092 1.06657 48.5144 0.704142C50.0392 0.405302 50.368 0.392594 51.8316 0.63421C53.3274 0.850392 61.2802 0.538832 62.1893 0.074676C62.4117 -0.0524901 62.5826 -0.00797814 62.5826 0.138263C62.5826 0.170054 68.1886 0.278142 68.2143 0.392591C68.2563 0.538832 68.4271 0.608769 68.5851 0.507036C68.8784 0.354437 74.8455 -0.198737 74.9389 0.227269C75.2323 1.44806 74.4103 6.64915 73.7172 7.91446C73.008 9.26878 69.1138 7.74915 65.9836 8.56302L51.4093 8.64568L48.2855 9.15433C47.9277 8.71561 46.5512 9.82196 45.7195 9.03353C45.3681 8.72197 42.8859 8.67745 42.3379 9.00809C41.8607 9.26242 29.1627 8.62023 27.8603 9.50403C25.5393 8.76647 16.1261 9.82197 13.9437 10Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineVoiceApiTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="299" height="10" viewBox="0 0 299 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.5888 10C49.4071 9.07169 4.14324 10.1081 0.853199 9.82831C-1.83281 9.45317 2.45967 2.84689 4.95291 1.87407C5.82682 1.52436 6.84211 1.47351 9.16827 1.63247C12.8567 1.93131 60.5495 1.33997 60.5495 1.33997C61.1793 1.54344 63.0685 0.856744 63.4155 1.10472C64.5079 1.68332 141.811 0.894891 148.404 0.8631C152.401 0.844025 149.239 1.23824 153.943 1.07928C163.967 0.761364 185.815 1.06657 193.411 0.704142C199.49 0.405302 200.801 0.392594 206.635 0.63421C212.598 0.850392 244.304 0.538832 247.928 0.074676C248.815 -0.0524901 249.496 -0.00797814 249.496 0.138263C249.496 0.170054 271.845 0.278142 271.948 0.392591C272.115 0.538832 272.796 0.608769 273.426 0.507036C274.595 0.354437 298.384 -0.198737 298.757 0.227269C299.926 1.44806 296.649 6.64915 293.886 7.91446C291.058 9.26878 275.533 7.74915 263.054 8.56302L204.952 8.64568L192.498 9.15434C191.072 8.71561 185.584 9.82196 182.268 9.03353C180.868 8.72197 170.972 8.67746 168.787 9.00809C166.885 9.26242 116.262 8.62023 111.07 9.50403C101.816 8.76647 64.2894 9.82197 55.5888 10Z" fill="#FFBA00" />
    </svg>
  </span>
)