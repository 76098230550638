import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="299" height="10" viewBox="0 0 299 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.5888 10C49.4071 9.07169 4.14324 10.1081 0.853199 9.82831C-1.83281 9.45317 2.45967 2.84689 4.95291 1.87407C5.82682 1.52436 6.84211 1.47351 9.16827 1.63247C12.8567 1.93131 60.5495 1.33997 60.5495 1.33997C61.1793 1.54344 63.0685 0.856744 63.4155 1.10472C64.5079 1.68332 141.811 0.894891 148.404 0.8631C152.401 0.844025 149.239 1.23824 153.943 1.07928C163.967 0.761364 185.815 1.06657 193.411 0.704142C199.49 0.405302 200.801 0.392594 206.635 0.63421C212.598 0.850392 244.304 0.538832 247.928 0.074676C248.815 -0.0524901 249.496 -0.00797814 249.496 0.138263C249.496 0.170054 271.845 0.278142 271.948 0.392591C272.115 0.538832 272.796 0.608769 273.426 0.507036C274.595 0.354437 298.384 -0.198737 298.757 0.227269C299.926 1.44806 296.649 6.64915 293.886 7.91446C291.058 9.26878 275.533 7.74915 263.054 8.56302L204.952 8.64568L192.498 9.15434C191.072 8.71561 185.584 9.82196 182.268 9.03353C180.868 8.72197 170.972 8.67746 168.787 9.00809C166.885 9.26242 116.262 8.62023 111.07 9.50403C101.816 8.76647 64.2894 9.82197 55.5888 10Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="171" height="10" viewBox="0 0 171 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.7916 10C28.2562 9.07169 2.36955 10.1081 0.48795 9.82831C-1.0482 9.45317 1.4067 2.84689 2.8326 1.87407C3.3324 1.52436 3.91305 1.47351 5.24339 1.63247C7.35284 1.93131 34.6287 1.33997 34.6287 1.33997C34.9888 1.54344 36.0693 0.856744 36.2677 1.10472C36.8925 1.68332 81.1027 0.894891 84.8732 0.8631C87.1591 0.844025 85.351 1.23824 88.0411 1.07928C93.7741 0.761364 106.269 1.06657 110.613 0.704142C114.089 0.405302 114.839 0.392594 118.176 0.63421C121.586 0.850392 139.719 0.538832 141.792 0.074676C142.299 -0.0524901 142.688 -0.00797814 142.688 0.138263C142.688 0.170054 155.47 0.278142 155.529 0.392591C155.624 0.538832 156.014 0.608769 156.374 0.507036C157.043 0.354437 170.648 -0.198737 170.861 0.227269C171.53 1.44806 169.655 6.64915 168.075 7.91446C166.458 9.26878 157.579 7.74915 150.443 8.56302L117.213 8.64568L110.091 9.15434C109.275 8.71561 106.137 9.82196 104.24 9.03353C103.439 8.72197 97.7798 8.67746 96.5303 9.00809C95.4425 9.26242 66.4909 8.62023 63.5215 9.50403C58.2295 8.76647 36.7675 9.82197 31.7916 10Z" fill="#FFA700" />
    </svg>
  </span>
)
